.footer-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 20px;
  margin: 20px;
  width: 100%;
}
.footer-sub-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 20%;
  justify-content: space-between;
}
@media screen and (max-width: 770px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-sub-container {
    width: 90%;
  }
}
