.skeltonImageContainer {
    padding: 10px;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 5px; 
    width: 100%;
}

.skelton-wrapper {
    margin-left: 10% !important;
    margin-right: 10% !important;
}

@media screen and (max-width: 770px) {
    .skelton-wrapper {
        margin-left: 4% !important;
        margin-right: 4% !important;
    }
}