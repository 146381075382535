@import "~bootstrap/scss/bootstrap.scss";
@import "~swiper/css/swiper.css";
@import "~animate.css/animate.min.css";
@import "~lightgallery.js/dist/css/lightgallery.css";
@import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";

@media (min-width: 576px) {
    .modal-cs {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}


.order-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.order-header-container-mobile {
    display: none !important;
}

/* Media query for mobile phones */
@media only screen and (max-width: 767px) {
    .order-header-container {
        display: none !important;
    }

    .order-header-container-mobile {
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
    }
}


.calender-class {
    display: block !important;
}

.calender-class-max {
    display: block !important;
    width: 185px;
}

.calender-class-input {
    background: transparent;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.react-google-flight-datepicker .date {
    width: fit-content !important;
}

.calender-class>div {
    display: flex;
    align-items: center;
}

.calender-class-max>div {
    display: flex;
    align-items: center;
}


.action-btn .btn {
    padding: 1px 8px;
    margin-bottom: 2px;
}

.google-date-picker-custom {
    background-color: transparent !important;
    padding: 0px !important;
}

.google-date-picker-custom .date-picker-input {
    height: 40px;
}

.cell-custom {
    text-align: center;
    padding-top: 10px
}

.text-container-comment {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 220px;
}

.text-container-comment-sub {
    width: 180px;
}

.text-container-comment-p {
    text-align: center;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}

.more-comment {
    padding-top: 10px;
    color: blue;
}

.more-comment > span {
    cursor: pointer;
}



.opacity-5 {
    opacity: 0.5;
}

#custom-menu {
    max-height: 80vh !important;
}

.itemVariation::first-letter {
    text-transform: uppercase;
}

.l-s {
    background: #eaeaea;
}

.up-f::first-letter {
    text-transform: uppercase;
}

.onMboBtn {
    width: 30%;
    float: 'right';
}

.viewer {
    width: 100%;
    height: 100vh;
}

.category {
    display: inline-block;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #dedede;
    margin: 5px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.5s;
    position: relative;
}

.category:hover {
    background-color: #1dc4e9;
    color: #fff;
}

.category .edit-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 22px;
    height: 22px;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1dc4e9;
    border-radius: 50%;
    transform: translateY(-50px);
    opacity: 0;
    transition: all 0.1s;
}

.category .edit-icon {
    visibility: visible;
    opacity: 1;
    transform: translateY(-25px);
}

.category .delete-icon {
    position: absolute;
    top: 0px;
    right: 30px;
    width: 22px;
    height: 22px;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1dc4e9;
    border-radius: 50%;
    transform: translateY(-50px);
    opacity: 0;
    transition: all 0.1s;
}

.category .delete-icon {
    visibility: visible;
    opacity: 1;
    transform: translateY(-25px);
}

@media (min-width: 1441px) {
    .l-s {
        background: transparent;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 769px) {
    .onMboBtn {
        width: 100%;
    }
    .container--verification {
        width: auto;
    }    
}

.customToggle input[type=checkbox] {
    background: transparent;
    color: transparent;
    border: 2px solid gray
}

.customToggle input[type=checkbox]:checked {
    background: black;
    color: white;
}

.approved-status {
    padding: 0.5em;
    border-radius: 8px;
    color: rgba(255, 174, 1);
    background-color: rgba(255, 247, 230)
}

.declined-status {
    padding: 0.5em;
    border-radius: 8px;
    color: rgba(222, 72, 109);
    background-color: rgba(253, 244, 246)
}

.pending-status {
    padding: 0.5em;
    border-radius: 8px;
    color: rgba(9, 56, 119);
    background-color: rgba(242, 244, 248)
}

.completed-status {
    padding: 0.5em;
    border-radius: 8px;
    color: rgba(47, 177, 130);
    background-color: rgba(236, 249, 244)
}

.to-paid-status {
    padding: 0.5em;
    border-radius: 8px;
    color: rgb(159, 166, 164);
    background-color: rgb(239, 241, 240);
    font-size: smaller;
    margin-left: 5px;
}

.paid-status {
    padding: 0.5em;
    border-radius: 8px;
    color: rgba(47, 177, 130);
    background-color: rgba(236, 249, 244);
    font-size: smaller;
    margin-left: 5px;
}

.delivered-status {
    padding: 0.5em;
    border-radius: 8px;
    color: rgba(47, 177, 130);
    background-color: rgba(236, 249, 244)
}

.onhold-status {
    padding: 0.5em;
    border-radius: 8px;
    color: rgba(222, 72, 109);
    background-color: rgba(253, 244, 246)
}

.shipped-status {
    padding: 0.5em;
    border-radius: 8px;
    color: rgba(255, 174, 1);
    background-color: rgba(255, 247, 230);
}

.default-status {
    padding: 0.5em;
    border-radius: 8px;
    color: rgba(9, 56, 119);
    background-color: rgba(242, 244, 248)
}

.character--selected {
    position: relative;
}

/* optionally hide placeholder (in case it overlaps the cursor) */
.character--selected:not(.vi__character--filled) {
    color: transparent;
}

.character--selected:not(.character--filled)::after {
    content: "";
    width: 1px;
    height: 25px;
    background-color: #333;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}