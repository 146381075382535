.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  /* width: 300px; */
}
.product-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}



@media screen and (max-width: 770px) {
  .product-img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
}