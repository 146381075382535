.top-bar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.custom-checkbox-shop {
  width: 15px;
  height: 15px;
}

.custom-checkbox-shop:active {
  border-color: purple;
  background: purple;
}
input[type="checkbox"]:checked {
  background: black;
  color: white;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: lightgray;
  height: 16px;
  width: 16px;
  border: 1px solid white;
  color: white;
}

input[type="checkbox"]:after {
  content: "✔";
  position: relative;
  left: 40%;
  top: 25%;
  width: 30%;
  height: 50%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}


/* @media screen and (max-width: 770px) {
  .top-bar-container {
    flex-direction: column;
  }
} */