.shop-by-container {
  background-color: #1d1f23;
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  height: 600px;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  /* padding-left: 10%;
  padding-right: 10%; */
}
.shop-by-container-sm {
  background-color: #1d1f23;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* height: 750px; */
  justify-content: space-evenly;
  align-items: center;
}
.category-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 40px;
}
