.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
}
.search-container {
  min-width: 17%;
  position: relative;
  padding-right: 0.75rem;
}
.search-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
.navbar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.collapse-styling {
  background-color: #ffffff;
}
.navbar-style-custom {
  background-color: transparent !important;
}
.collapse-item {
  color: white;
  font-size: 17px;
  font-weight: 600;
  margin: 0;
}
.collapse-item-sub-menu {
  color: darkgray;
  font-size: 17px;
  font-weight: 400;
  margin-left: 10px;
  margin-bottom: 0px;
}
.collapse-item-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.collapse-item-container-login {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}
.custom-dropdown-toggle::after {
  display: none !important;
}
.dropdown-item:hover {
  /* color: #16181b !important; */
  background-color: transparent !important;
}
/* .dropdown-toggle::after {
  display: none !important;
} */
.mega-menu {
  position: absolute;
  top: 90px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 40px;
  background: rgba(0, 0, 0, 0.88);
  backdrop-filter: blur(5px);
  /* transition: all 0.5s ease-in-out;
  animation: animationname 0.1s linear; */
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.accordion-card {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.sub-category-headings {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #606060;
  position: relative;
  padding: 20px;
  justify-content: center;
  min-width: 150px;
}
.brand-details-headings {
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 150px;
}
@media screen and (max-width: 770px) {
  .login-container {
    justify-content: space-evenly;
  }
  .collapse-styling {
    background: rgba(36, 36, 36, 0.85) !important;
    backdrop-filter: blur(13px) !important;
  }
}
@media screen and (max-width: 900px) {
  .search-container {
    width: 100%;
    margin-top: 20px;
  }
}
