.tag-card-container {
  background: #ffffff;
  box-shadow: 0px 60px 190.612px rgba(27, 49, 66, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-height: 90%;
  cursor: pointer;
}
.brand-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 131.69%;
  /* or 21px */

  color: #000000;
  margin-top: 10px;
}
